
import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    date_time_to_date_format,
    getCustomer, getRentsByCustomerId,
    getRoom,
    deleteCustomerDocuments,addCustomerDocuments,get_response_or_errors, remove_session
} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import PrintTransation from "../transactions/PrintTransation"
import ModalImage from "react-modal-image";
import { Col, Row } from "react-bootstrap";

function PrintFormCustomer(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [previewdocuments, setCustomerDocs] = React.useState([]);
    const [customer, setCustomer] = React.useState([]);
    const [rent, setRent] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);


   
    useEffect(() => {
        getCustomer(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setCustomer(res.data.data);
                    setCustomerDocs(res.data.documents);
                } else {
                    navigate('/customers');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        getRentsByCustomerId(params.id).then(res => {
            if (res && res.status == 200) {
                setRent(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }, [])
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const yearDiff = (dateFrom, dateTo) => {
        var years = dateTo.getFullYear() - dateFrom.getFullYear();

        if(dateTo.getDate() < dateFrom.getDate()){
            years--;
        }
        return years;
    }
    const getRentTillNow = (currentRent) => {
        let rentAmount = 0;
        if(currentRent.startDate){
            if(currentRent.rentType === '1'){
                let months = monthDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * months
            }else{
                let years = yearDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * years
            }
        }
        return rentAmount + parseInt(currentRent.overDaysRent);
    }
    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // Update the current page number when the component mounts
        const updatePageNumber = () => {
            setCurrentPage(window.location.href);
        };

    window.addEventListener("beforeprint", updatePageNumber);
    window.addEventListener("afterprint", updatePageNumber);

    return () => {
        window.removeEventListener("beforeprint", updatePageNumber);
        window.removeEventListener("afterprint", updatePageNumber);
    };
}, []);

  return (
      <div className="w-100 pt-0 mt-0 print-layout">
    <div className="text-right mb-3">
                <button className="btn btn-primary  print-button" onClick={handlePrint}>
                <i className="ri-printer-line mr-0"></i>
                </button>
    </div> 
      <Row className="justify-content-center align-items-center header-text text-center mt-0">
        <Col md={4}>
            <h1 className="fw-bold">Burak Al Ardh Technical services LLC</h1>
        </Col>
        <Col md={2} className="text-center">
            <Link to="/" className="print-logo-2 p-0 ">
                <img src={`${process.env.PUBLIC_URL}/logoPrint.png`}  className="img-fluid p-0  " alt="logo"/>
            </Link>
        </Col>
        <Col md={4} className="text-left">
            <h1>بوراك الأرض</h1>
        </Col>
      </Row>
      
        <div className="col-12">
            <div className="row mt-5 mb-3 bg-primary mx-1 py-3 rounded">
                <div className="col-4 text-center ">
                    <h5 className="text-white">+971 55 318 6233</h5>
                </div>
                <div className="col-4 text-center text-white">
                    <h5 className="text-white"> admin@burakalardh.com</h5>
                </div>
                <div className="col-4 text-center text-white">
                    <h5 className="text-white">www.www.burakalardh.com</h5>
                </div>
            </div>
        </div>
          <div className="col-12 main-tanent">
              <div className="card print-card">
                  <div className=" pb-0 mb-0 px-5">
                        <div className="text-center mb-3 red d-flex justify-content-center top">
                            <p className="bg-primary  red py-3 h4 px-5">
                                OWNER & TENANT PERSONAL RENT AGREEMENT
                            </p>
                        </div>
                        
                          <div className="row tenant-print">

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Building/Villa/Camp: </label>
                                        {rent?.length > 0 && rent.map((singleRent, index) => {
                                            if (singleRent.endDate) {
                                                return null;
                                            }
                                            if (index === 0) {
                                                return (
                                                    <span key={index}>{singleRent.buildingName}</span>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Room No: </label>
                                        {rent?.length > 0 && rent.map((singleRent, index) => {
                                            if (singleRent.endDate) {
                                                return null;
                                            }
                                            if (index === 0) {
                                            return (
                                                <span key={index}>{singleRent.roomName}</span>
                                            );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>

                                <div className="col-6">
                                  <div className="form-group">
                                  <label>Flat No/ Room: </label>  {rent?.length > 0 && rent[0].roomName? rent[0].roomName : 'N/A'}
                                  </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                    <label>Villa No: </label> {customer.flatNo}
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Yearly Rent: </label> {rent?.length > 0 && rent[0].rentType == '2' ? rent[0].rent : 'N/A'}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Monthly: </label> {rent?.length > 0 && rent[0].rentType == '1' ? rent[0].rent : 'N/A'}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                    <label>Inc. Dewa </label> 
                                  
                                        {rent.length > 0 && rent.map((singleRent, index) => {
                                            if (singleRent.endDate) {
                                                return null;
                                            }
                                            if (index === 0) {
                                            return (
                                                <span key={index}>{singleRent.dewa === '1' ?
                                                (<span className="font-weight-bold text-success">Yes</span>) :
                                                (<span className="font-weight-bold text-danger">No&nbsp;</span>)}</span>
                                            );
                                            }
                                            return null
                                        })}
                                    </div>
                                </div>


                                <div div className="col-6">
                                    <div className="form-group">
                                        <label>Exc. Dewa </label> 
                                        {rent?.length > 0 && rent.map((singleRent, index) => {
                                                if (singleRent.endDate) {
                                                    return null;
                                                }
                                                if (index === 0) {
                                                return (
                                                    <span key={index}>{singleRent.dewa === '1' ?
                                                    (<span className="font-weight-bold text-success">Yes</span>) :
                                                    (<span className="font-weight-bold text-danger">No&nbsp;</span>)}</span>
                                                );
                                                }
                                                return null;
                                        })}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Starting Date: </label> 
                                        {rent?.length > 0 && rent.map((singleRent, index) => {
                                            if (singleRent.endDate) {
                                                return null;
                                            }
                                            if (index === 0) {
                                            return (
                                                <span key={index}>{date_time_to_date_format(singleRent.startDate)}</span>
                                            );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Days Rent: </label>
                                        {rent?.length > 0 && rent.map((singleRent, index) => {
                                            if (singleRent.endDate) {
                                                return null;
                                            }
                                            if (index === 0) {
                                            return (
                                                <span key={index}>{singleRent.rent}</span>
                                            );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group ">
                                        <label>Tenant Name: </label>  <span className="ms-5 ps-5">{customer.name}</span> <br/>
                                        <label>Tenant Email: </label>  <span className="ms-5 ps-5">{customer.email}</span> 
                                        
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Mobile No: </label> {customer.phone}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>EID No: </label> {customer.emiratesId}
                                        {customer.emiratesIdImage &&
                                        <div  className="transactionTableImage">
                                            <ModalImage
                                                small={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                                                large={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                                            />
                                        </div>}
                                  </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Nationality: </label> {customer.country}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Category: </label> {customer.category??'N/A'}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Members: </label> {customer.no_of_members??'N/A'}
                                        
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="form-group">
                                        <label>Sec. Deposit </label>
                                        <span>{rent?.length > 0 && rent[0].securityDeposit ? rent[0].securityDeposit : 'N/A'}</span>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Note:</label> 
                                    </div>
                                </div>
                            </div>
                  </div>
                  <div className="list-print pt-5">
                    <span className="bg-primary px-5 py-2 h4 mb-5 mx-4 term">TERMS & CONDITIONS</span>
                      <ol className="mt-4 ps-0">
                        <li>All the tenants must following rules and regulation.  </li>
                        <li>All the tenants should pay the rent on the 1st date of every month.  </li>
                        <li>All the tenants should pay the Dewa bills every month on time and regularly.</li>
                        <li>All the tenant room / flat maintenance work should be on tenant side.</li>
                        <li>If any tenants want to vacate the room / flat, tenant should inform the owner before 1 
                            month. If the tenant did not inform owner about vacating the flat / room, tenant must 
                            pay the full month rent.  </li>
                        <li>If the tenant will not pay the rent on time, owner has full right to take any action against 
                            the tenant to collect the balance rent and will vacate the room / flat from the tenant 
                            immediately. </li>
                        <li>Read the above mentioned terms and condition carefully before signing the paper. </li>
                      </ol>
                </div>
                <div className="row m-5">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Owner Signature:</label>
                            <div className="dashed-line"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Tenant Signature:</label>
                            <div className="dashed-line"></div>
                        </div>
                    </div>
                </div>
                  
              </div>
              
          </div>         

          
        
      </div>
  );
}

export default PrintFormCustomer;