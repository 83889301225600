
import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    date_time_to_date_format,
    getCustomer, getRentsByCustomerId,
    getRoom,
    deleteCustomerDocuments,addCustomerDocuments,get_response_or_errors, remove_session
} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import PrintTransation from "../transactions/PrintTransation"
import ModalImage from "react-modal-image";
import { Col, Row } from "react-bootstrap";

function PrintCustomer(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [previewdocuments, setCustomerDocs] = React.useState([]);
    const [customer, setCustomer] = React.useState([]);
    const [rent, setRent] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);


   
    useEffect(() => {
        getCustomer(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setCustomer(res.data.data);
                    setCustomerDocs(res.data.documents);
                } else {
                    navigate('/customers');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        getRentsByCustomerId(params.id).then(res => {
            if (res && res.status == 200) {
                setRent(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }, [])
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const yearDiff = (dateFrom, dateTo) => {
        var years = dateTo.getFullYear() - dateFrom.getFullYear();

        if(dateTo.getDate() < dateFrom.getDate()){
            years--;
        }
        return years;
    }
    const getRentTillNow = (currentRent) => {
        let rentAmount = 0;
        if(currentRent.startDate){
            if(currentRent.rentType === '1'){
                let months = monthDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * months
            }else{
                let years = yearDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * years
            }
        }
        return rentAmount + parseInt(currentRent.overDaysRent);
    }
    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // Update the current page number when the component mounts
        const updatePageNumber = () => {
            setCurrentPage(window.location.href);
        };

    window.addEventListener("beforeprint", updatePageNumber);
    window.addEventListener("afterprint", updatePageNumber);

    return () => {
        window.removeEventListener("beforeprint", updatePageNumber);
        window.removeEventListener("afterprint", updatePageNumber);
    };
}, []);

  return (
      <div className="w-100 pt-0 mt-0 print-layout">
    <div className="text-right mb-3">
                <button className="btn btn-primary  print-button" onClick={handlePrint}>
                <i className="ri-printer-line mr-0"></i>
                </button>
    </div> 
      <Row className="justify-content-center text-center mt-0">
        <Col md={1} className="text-center">
            <Link to="/" className="print-logo p-0 ">
                <img src={`${process.env.PUBLIC_URL}/logoPrint.png`} className="img-fluid p-0  " alt="logo"/>
            </Link>
        </Col>
      </Row>
      <Row className="justify-content-center text-center my-3">
        <Col md={5} className="text-center">
            <h2>Payment Receipt</h2>
            <h5>Receipt-{customer.receipt} </h5>
        </Col>
      </Row>
      
      <div className="col-12">
      <Row className="mt-5 mb-3">
      <Col>
                    <h5>Date: &nbsp; &nbsp; &nbsp; <span className="h6"> {new Date().toLocaleDateString()}</span></h5>
                </Col>
                <Col>
                    <h5>Contract No: &nbsp; &nbsp; &nbsp; <span className="h6">{customer.contractNumber}</span> </h5>
                </Col>
                <Col>
                    <h5>Pages: &nbsp; &nbsp; &nbsp; <span className="h6">{currentPage}</span> </h5>
                </Col>
      </Row>
    </div>
          <div className="col-12">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Tenant</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row tenant-print">
                              <div className="col-6">
                                  <div className="form-group ">
                                      <label>Tenant Name: </label>  <span className="ms-5 ps-5">{customer.name}</span> 
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Nationality: </label> {customer.country}
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Passport: </label> {customer.passport}
                                  </div>
                              </div>
                             
                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Emirates&nbsp;Id: </label> {customer.emiratesId}
                                  {customer.emiratesIdImage &&
                                  <div  className="transactionTableImage">
                                      <ModalImage
                                          small={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                                          large={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                                      />
                                  </div>}
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Visa No: </label> 
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Mobile No: </label> {customer.phone}
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Email: </label> {customer.email}
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Tel No: </label> {customer.phone}
                                  </div>
                              </div>
                              <div className="col-6">
                                  <div className="form-group">
                                  <label>Address: </label> {customer.address}
                                  </div>
                              </div>
                          </div>
                  </div>
                  
              </div>
          </div>




          <div className="col-12">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Leased Unit</h4>
                      </div>
                  </div>
                  <div className="card-body">
                  <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info ">
                      <thead className=" text-uppercase print-table w-100">
                      <tr className="text-dark w-100">
                          <th>Building</th>
                          <th>Room</th>
                          <th>Tenant</th>
                          <th>Rent</th>
                          <th>Pending</th>
                          <th>Start Date</th>
                          <th>Dewa</th>
                          <th>Maintenance</th>
                      </tr>
                      </thead>
                      <tbody className="bg-white">
                      { rent?.length > 0 && rent.map((singleRent, index) => {
                          if(singleRent.endDate){
                              return;
                          }
                              return (
                                  <tr key={index}>
                                      <td>{singleRent.buildingName}</td>
                                      <td>{singleRent.roomName}</td>
                                      <td>{singleRent.customerName}</td>
                                      <td>
                                          {singleRent.rent} / {singleRent.rentType === '1' ? "Month" : "Year"}
                                          {singleRent.contractedImage &&
                                          <div  className="transactionTableImage">
                                              <ModalImage
                                                  small={process.env.REACT_APP_APIPATH + "/api/images/" + singleRent.contractedImage}
                                                  large={process.env.REACT_APP_APIPATH + "/api/images/" + singleRent.contractedImage}
                                              />
                                          </div>}
                                      </td>
                                      <td>{(getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) > 0 ? (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) : 0}</td>
                                      <td>{date_time_to_date_format(singleRent.startDate)}</td>
                                      <td>
                                          {singleRent.dewa === '1' ?
                                              (<div className="font-weight-bold text-success">Yes</div>) :
                                              (<div className="font-weight-bold text-danger">No&nbsp;</div>)}
                                      </td>
                                      <td>
                                          {singleRent.maintenance === '1' ?
                                              (<div className="font-weight-bold text-success">Yes</div>) :
                                              (<div className="font-weight-bold text-danger">No&nbsp;</div>)}
                                      </td>
                                  </tr>
                              );
                      })}
                      </tbody>
                  </table>
              </div>
                  </div>
                  
              </div>
          </div>

          
          
          <div className="col-lg-12">

              <PrintTransation 
                className="w-100"
                customerId={params.id}
                transactionType="1"
                transactionFor="customer"/>
          </div>
          
        
      </div>
  );
}

export default PrintCustomer;