
import React, {useEffect} from "react";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {
    date_time_to_date_format,
    getBuilding, getRentsByCustomerId,
    getOwner,
    deleteCustomerDocuments,addCustomerDocuments,get_response_or_errors, remove_session
} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import PrintTransation from "../transactions/PrintTransation"
import ModalImage from "react-modal-image";
import { Col, Row } from "react-bootstrap";

function PrintBuilding(props) {
   
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const location = useLocation();
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [building, setBuilding] = React.useState([]);
    const [roomsCount, setRoomsCount] = React.useState([]);
    const [rent, setRent] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [owner, setOwner] = React.useState([]);
    const [ownerId, setOwnerId] = React.useState("");
    const currentUser = location.state || {};
    console.log(location.state);
    useEffect(() => {
        getBuilding(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setBuilding(res.data.data);
                    setOwnerId(res.data.data.ownerId)
                    setRoomsCount(res.data.roomsCount);
                } else {
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        getOwner(ownerId).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setOwner(res.data.data);
                } else {
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [ownerId])
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const yearDiff = (dateFrom, dateTo) => {
        var years = dateTo.getFullYear() - dateFrom.getFullYear();

        if(dateTo.getDate() < dateFrom.getDate()){
            years--;
        }
        return years;
    }
    const getRentTillNow = (currentRent) => {
        let rentAmount = 0;
        if(currentRent.startDate){
            if(currentRent.rentType === '1'){
                let months = monthDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * months
            }else{
                let years = yearDiff(new Date(currentRent.startDate), new Date()) + 1;
                rentAmount = currentRent.rent * years
            }
        }
        return rentAmount + parseInt(currentRent.overDaysRent);
    }
    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // Update the current page number when the component mounts
        const updatePageNumber = () => {
            setCurrentPage(window.location.href);
        };

    window.addEventListener("beforeprint", updatePageNumber);
    window.addEventListener("afterprint", updatePageNumber);

    return () => {
        window.removeEventListener("beforeprint", updatePageNumber);
        window.removeEventListener("afterprint", updatePageNumber);
    };
}, []);

  return (
      <div className="w-100 pt-0 mt-0 print-layout">
        <div className="text-right mb-3">
                    <button className="btn btn-primary  print-button" onClick={handlePrint}>
                    <i className="ri-printer-line mr-0"></i>
                    </button>
        </div> 
        <Row className="justify-content-center align-items-center header-text text-center mt-0">
            <Col md={4}>
                <h1 className="fw-bold">Burak Al Ardh Technical services LLC</h1>
            </Col>
            <Col md={2} className="text-center">
                <Link to="/" className="print-logo-2 p-0 ">
                    <img src={`${process.env.PUBLIC_URL}/logoPrint.png`} className="img-fluid p-0  " alt="logo"/>
                </Link>
            </Col>
            <Col md={4} className="text-left">
                <h1>بوراك الأرض</h1>
            </Col>
        </Row>
        <div className="col-12">
            <div className="row mt-5 mb-3 bg-primary mx-1 py-3 rounded">
                <div className="col-4 text-center ">
                    <h5 className="text-white">Date: &nbsp; &nbsp; &nbsp; <span className="h6"> {new Date().toLocaleDateString()}</span></h5>
                </div>
                <div className="col-4 text-center text-white">
                    <h5 className="text-white">Building: &nbsp; &nbsp; &nbsp; <span className="h6">{building.name}</span> </h5>
                </div>
                <div className="col-4 text-center text-white">
                    <h5 className="text-white">Pages: &nbsp; &nbsp; &nbsp; <span className="h6">{currentPage}</span> </h5>
                </div>
            </div>
        </div>
        <div className="col-12">
            <div className="card print-card">
                <div className=" pb-0 mb-0 px-5">
                    <div className="text-center mb-3 red d-flex justify-content-start top">
                        <p className="bg-primary  red py-3 h4 px-5">
                            Owner Details
                        </p>
                    </div>
                    <div className="row tenant-print">
                            <div className="col-6">
                                <div className="form-group ">
                                    <label>Owner Name: </label>  
                                    <span className="ms-5 ps-5">{owner.name}</span>
                                
                                </div>
                            </div>

                            {/* <div className="col-6">
                                <div className="form-group">
                                <label>Nationality: </label> {customer.country}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                <label>Passport: </label> {customer.passport}
                                </div>
                            </div> */}
                            
                            <div className="col-6">
                                <div className="form-group">
                                <label>Emirates&nbsp;Id: </label> {owner.emiratesId}
                                {owner.emiratesIdImage &&
                                <div  className="transactionTableImage">
                                    <ModalImage
                                        small={process.env.REACT_APP_APIPATH + "/api/images/" + owner.emiratesIdImage}
                                        large={process.env.REACT_APP_APIPATH + "/api/images/" + owner.emiratesIdImage}
                                    />
                                </div>}
                                </div>
                            </div>

                            {/* <div className="col-6">
                                <div className="form-group">
                                <label>Visa No: </label> 
                                </div>
                            </div> */}

                            <div className="col-6">
                                <div className="form-group">
                                <label>Mobile No: </label> {owner.phone}
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                <label>Email: </label>  {owner.email? owner.email:'--'}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>City: </label> {owner.city} <br/>
                                <label>Country: </label> {owner.country} <br/>
                                <label>State: </label> {owner.state} <br/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>Address: </label> {owner.address}
                                </div>
                            </div>
                        </div>
                        
                </div>
                
            </div>
        </div>
        <div className="col-12">
            <div className="card print-card">
                <div className=" pb-0 mb-0 px-5">
                    <div className="text-center mb-3 red d-flex justify-content-start top">
                        <p className="bg-primary  red py-3 h4 px-5">
                            Leaser Details
                        </p>
                    </div>
                        <div className="row tenant-print">
                            <div className="col-6">
                                <div className="form-group ">
                                    <label>Name: </label>  
                                    <span className="ms-5 ps-5">Burak Al Ardh Technical services LLC</span>
                                
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>Mobile No: </label> +971553186233
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                <label>Email: </label>  admin@burakalardh.com
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>Address: </label> Deira Dubai UAW
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                <label>Website: </label> www.www.burakalardh.com
                                </div>
                            </div>
                        </div>
                </div>
                
            </div>
        </div>
        <div className="col-12">
            <div className="card print-card">
            <div className=" pb-0 mb-0 px-5">
                <div className="text-center mb-3 red d-flex justify-content-start top">
                    <p className="bg-primary  red py-3 h4 px-5">
                        Lease Unit
                    </p>
                </div>
                <div className="row tenant-print">
                    <div className="col-md-12">
                        <div className="row justify-content-between form-group">
                        <div className="col-md-12">  <h4 className="card-title text-white">{building.name}</h4></div>
                        <div className="col-md-6">
                            <label>City: </label> {building.city} &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="col-md-6">
                            <label>State: </label> {building.state}
                        </div>
                        <div className="col-md-6">
                            <label>Address: </label> {building.address}
                        </div>
                        <div className="col-md-6">
                            <label>Rent: </label> {building.rent} / {building.rentType === '1' ? 'Month' : 'Year'}
                                {building.contractedImage &&
                                <div  className="transactionTableImage">
                                    <ModalImage
                                        small={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                                        large={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                                    />
                                </div>}
                        </div>
                        <div className="col-md-6">
                            <label>No of Cheques: </label> {building.cheques}
                        </div>

                        <div className="col-md-6">
                            <label>Account Number: </label> {building.accountNumber}
                        </div>

                        <div className="col-md-6">
                            <label>Dewa: </label> {building.dewa === '1' ? 'Included' : 'Not Included'} 
                        </div>

                        <div className="col-md-6">
                        <label>Maintenance Charges: </label> {building.maintenance === '1' ? 'Included' : 'Not Included'}
                        </div>

                        <div className="col-md-6">
                        <label>Security Deposit: </label> {building.securityDeposit}
                        </div>
                        <div className="col-md-6">
                        <label>Total Rooms: </label> {roomsCount}
                        </div>

                        <div className="col-md-6">
                        {building.startDate &&
                                <>
                                    <label>Start Date: </label> {building.startDate}
                                </>}
                        </div>
                        <div className="col-md-6">
                        {building.endDate &&
                            <>
                                <label>End Date: </label> {building.endDate}
                            </>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </div>
        <div className="col-lg-12">

            <ListTransactions 
            className="w-100"  currentUser={currentUser}
            buildingId={params.id}  transactionFor="building"/>
             
        </div>
        <div className="col-lg-12">
            <div className="row m-5">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Leaser Signature:</label>
                        <div className="dashed-line"></div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Owner Signature:</label>
                        <div className="dashed-line"></div>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
  );
}

export default PrintBuilding;