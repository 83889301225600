import React from "react";
import {Link} from "react-router-dom";

function Sidebar(props) {
    const currentUser = props.currentUser;
    const currentUserPrivilege = props.currentUserPrivilege;
    const onMenuClick = () => {
        if(window.screen.width < 1300){
            document.getElementsByClassName("las la-bars wrapper-menu")[0].click();
        }
    }
    return (
        <div className="iq-sidebar  sidebar-default bg-black-custom">
            <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                <Link onClick={() => onMenuClick()} to="/" className="header-logo d-flex align-items-center">
                    <img src={`${process.env.PUBLIC_URL}/logoPrint.png`}
                         className="img-fluid rounded-normal light-logo" alt="logo"/>
                         <h6 className="logo-title light-logo ml-3 text-white">{process.env.REACT_APP_TITLE}</h6>
                </Link>
                <div className="iq-menu-bt-sidebar ml-0">
                    <i className="las la-bars wrapper-menu text-white"></i>
                </div>
            </div>
            <div className="data-scrollbar" data-scroll="1">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">
                        <li className="active">
                            <Link onClick={() => onMenuClick()} to="/" className="svg-icon">
                                <svg className="svg-icon" id="p-dash1" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path
                                        d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                </svg>
                                <span className="ml-4">Dashboards</span>
                            </Link>
                        </li>
                        {((currentUser.isSuperAdmin == 1) || (currentUserPrivilege!=null && currentUserPrivilege.owner == 1)) && (
                        <li className=" ">
                            <a href="#owners" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg>
                                <span className="ml-4">Owners</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="owners" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="owners">
                                        <i className="las la-minus"></i><span>List Owners</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="owners/add">
                                        <i className="las la-minus"></i><span>Add Owners</span>
                                    </Link>
                                </li>
                                )}
                              
                            </ul>
                        </li> )}
                        {((currentUser.isSuperAdmin == 1) || (currentUserPrivilege!=null && currentUserPrivilege.building == 1)) && (
                        <li className=" ">
                            <a href="#buildings-villas" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                {/* <svg className="svg-icon" id="p-dash5" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg> */}
                                <svg className="svg-icon" id="building-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">

                                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>

                                    <line x1="4" y1="4" x2="4" y2="20"></line>
                                    <line x1="12" y1="4" x2="12" y2="20"></line>
                                    <line x1="20" y1="4" x2="20" y2="20"></line>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                    <line x1="1" y1="16" x2="23" y2="16"></line>
                                </svg>
                                <span className="ml-4">Buildings/Villas/Camps</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="buildings-villas" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="buildings-villas" >
                                        <i className="las la-minus"></i><span>List Buildings/Villas</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="buildings-villas/add">
                                        <i className="las la-minus"></i><span>Add Buildings/Villas</span>
                                    </Link>
                                </li>
                                )}
                            </ul>
                        </li>)}
                        {((currentUser.isSuperAdmin == 1) || (currentUserPrivilege!=null && currentUserPrivilege.room == 1)) && (
                        <li className=" ">
                            <a href="#rooms" className="collapsed" data-toggle="collapse" aria-expanded="false">
                            <svg className="svg-icon" id="rooms-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    
                            <rect x="1" y="4" width="7" height="7" rx="2" ry="2"></rect>
                            
                            
                            <rect x="9" y="4" width="7" height="7" rx="2" ry="2"></rect>
                            
                            
                            <rect x="17" y="4" width="7" height="7" rx="2" ry="2"></rect>
                            
                            
                            <rect x="1" y="13" width="7" height="7" rx="2" ry="2"></rect>
                            
                            
                            <rect x="9" y="13" width="7" height="7" rx="2" ry="2"></rect>
                            
                            
                            <rect x="17" y="13" width="7" height="7" rx="2" ry="2"></rect>
                        </svg>
                                <span className="ml-4">Rooms</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="rooms" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="rooms">
                                        <i className="las la-minus"></i><span>List Rooms</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="rooms/add">
                                        <i className="las la-minus"></i><span>Add Rooms</span>
                                    </Link>
                                </li>
                                )}
                            </ul>
                        </li>)}
                        {((currentUser.isSuperAdmin == 1) || (currentUserPrivilege!=null && currentUserPrivilege.tenant == 1 )) && (
                        <li className=" ">
                            <a href="#tanents" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg>
                                <span className="ml-4">Tenants</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="tanents" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="customers">
                                        <i className="las la-minus"></i><span>List Tenants</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="customers/add">
                                        <i className="las la-minus"></i><span>Add Tenants</span>
                                    </Link>
                                </li>
                                )}
                            </ul>
                        </li>)}
                        {((currentUser.isSuperAdmin == 1) || (currentUserPrivilege!=null && currentUserPrivilege.rent == 1)) && (
                        <li className=" ">
                            <a href="#rent" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                {/* <svg className="svg-icon" id="p-dash5" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg> */}

                                <svg className="svg-icon" id="rent-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                
                                    <rect x="4" y="9" width="16" height="10"></rect>
                                    <path d="M4 9V5l8-4 8 4v4"></path>
                                </svg>


                                <span className="ml-4">Rent</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="rent" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="rent">
                                        <i className="las la-minus"></i><span>List Rents</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="rent/add">
                                        <i className="las la-minus"></i><span>Add Rent</span>
                                    </Link>
                                </li>
                                )}
                            </ul>
                        </li>)}
                        <li className="">
                            <a href="#transactions" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                {/* <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg> */}

                                <svg className="svg-icon" id="credit-card-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    
                                    <rect x="2" y="4" width="20" height="16" rx="2" ry="2"></rect>
                                    

                                    <rect x="2" y="15" width="20" height="2" rx="1" ry="1"></rect>

                                    <rect x="5" y="8" width="6" height="4" rx="1" ry="1"></rect>
                                    
                                
                                    {/* <text x="4" y="19" fontSize="4" fill="currentColor">****</text>
                                    <text x="14" y="19" fontSize="4" fill="currentColor">****</text>
                                    <text x="8" y="12" fontSize="4" fill="currentColor">1234</text> */}
                                    

                                    {/* <text x="4" y="17" fontSize="2" fill="currentColor">Cardholder Name</text> */}
                                    
                                    {/* <text x="16" y="17" fontSize="2" fill="currentColor">MM/YY</text>
                                    

                                    <text x="20" y="17" fontSize="2" fill="currentColor">Visa</text> */}
                                </svg>
                                <span className="ml-4">Transactions</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="transactions" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                {/* {((currentUser.isSuperAdmin == '1')) &&
                                <li>
                                        <Link onClick={() => onMenuClick()} to={`/transactions/add`}>
                                            <i className="las la-minus"></i><span>Add Transactions</span>
                                        </Link>
                                </li>
                                } */}
                                {((currentUser.isSuperAdmin == '1') ||(currentUserPrivilege && currentUserPrivilege.transactions == 1 )) &&
                               
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="transactions">
                                        <i className="las la-minus"></i><span>List All Transactions</span>
                                    </Link>
                                </li>}
                                {currentUser.isSuperAdmin == '1' &&
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="transactions/rent">
                                        <i className="las la-minus"></i><span>Rent Transactions</span>
                                    </Link>
                                </li>
                                }
                            </ul>
                        </li>

                        {currentUser.isSuperAdmin == '1' &&
                        <li className="">
                            <a href="#admins" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg>
                                <span className="ml-4">Admins</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="admins" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="admins">
                                        <i className="las la-minus"></i><span>List Admins</span>
                                    </Link>
                                </li>
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="admins/add">
                                        <i className="las la-minus"></i><span>Add Admins</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        }
                       
                     
                        {((currentUser.isSuperAdmin == '1') ||(currentUserPrivilege && currentUserPrivilege.employees == 1 )) &&
                        <li className="">
                            <a href="#employees" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg>
                                <span className="ml-4">Employees</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="employees" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="employees">
                                        <i className="las la-minus"></i><span>List Employees</span>
                                    </Link>
                                </li>
                                {(currentUser.isSuperAdmin == 1) && (
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="employees/add">
                                        <i className="las la-minus"></i><span>Add Employees</span>
                                    </Link>
                                </li>
                                )}
                            </ul>
                        </li>
                        }
                       
                        {currentUser.isSuperAdmin == '1' &&
                        <li className="">
                            <a href="#accounts" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                <svg className="svg-icon" id="p-dash8" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </svg>
                                <span className="ml-4">Accounts</span>
                                <svg className="svg-icon iq-arrow-right arrow-active" width="20" height="20"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <ul id="accounts" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="account">
                                        <i className="las la-minus"></i><span>List Accounts</span>
                                    </Link>
                                </li>
                                <li className="">
                                    <Link onClick={() => onMenuClick()} to="account-transactions">
                                        <i className="las la-minus"></i><span>Bank Transactions</span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        }

                        {currentUser.isSuperAdmin == '1' &&
                        <li className="">
                            <Link onClick={() => onMenuClick()} to="/reports" className="svg-icon">
                            <svg className="svg-icon" id="bar-chart-icon" width="20" height="20"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round">
                                {/* Replace this path with the SVG path for your bar chart icon */}
                                <rect x="3" y="11" width="6" height="11"></rect>
                                <rect x="9" y="2" width="6" height="20"></rect>
                                <rect x="15" y="6" width="6" height="16"></rect>
                            </svg>
                                <span className="ml-4">Reports</span>
                            </Link>
                        </li>
                        }
                    </ul>
                </nav>
                <div className="p-3"></div>
            </div>
        </div>
    );
}

export default Sidebar;
