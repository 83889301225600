import React from "react";
import dummyUser from '../images/dummy_user.png';
import {remove_session} from "../functions/general";
import {Link, useNavigate} from "react-router-dom";
function TopNavigation(props) {
    const navigate = useNavigate();
    const currentUser = props.currentUser;
    const signOut = () => {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
    }
    return (
        <div className="iq-top-navbar bg-black-custom ">
            <div className="iq-navbar-custom ">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
                        <i className="ri-menu-line wrapper-menu"></i>
                        <a href="/" className="header-logo">
                            <img src={`${process.env.PUBLIC_URL}/logoPrint.png`}
                                 className="img-fluid rounded-normal" alt="logo" />
                                <h5 className="logo-title ml-3">{process.env.REACT_APP_TITLE}</h5>
                        </a>
                    </div>
                    <div className="iq-search-bar device-search">
                    </div>
                    <div className="d-flex align-items-center">
                        {/* <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-label="Toggle navigation">
                            <i className="ri-menu-3-line"></i>
                        </button> */}
                        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
                        <div className="" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto navbar-list align-items-center">
                                <li className="nav-item nav-icon dropdown caption-content">
                                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton4"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={dummyUser}
                                             className="img-fluid rounded" alt="user"/>
                                    </a>
                                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div className="card shadow-none m-0">
                                            <div className="card-body p-0 text-center">
                                                <div className="p-3">
                                                    <h5 className="mb-1">{currentUser.email}</h5>
                                                    <div
                                                        className="d-flex align-items-center justify-content-center mt-3">
                                                        <Link to="/profile"
                                                           className="btn border mr-2">Profile</Link>
                                                        <button onClick={() => signOut()} className="btn border">Sign Out</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}
export default TopNavigation;